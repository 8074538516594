// import { Link } from "react-router-dom";

const S5 = () => {
    return (
        <div className="paper-details">
            <h1>S5 Math exercise Free PDF download</h1>
            <h1>中五數學練習免費PDF下載</h1>
            <div className="paper-list">
                <h2>Ch 1: More about Equations 續方程</h2>
                <a href="https://drive.google.com/file/d/1xqE-6ua5zMM0MkHDevYBqzja85J2MPAN/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1w5qDfugVr__9syH3uI_LfmsqrE2FOW4D/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 2: Quadratic Inequalities in One Unknown 一元二次不等式</h2>
                <a href="https://drive.google.com/file/d/1ZW05DxM22p28iK5H_hiAssgk1ePioaCE/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1wMsFOauohXCfC5rnoWp3ok6aByiYTBu5/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            
        </div>
    );
}

export default S5;

