// import { Link } from "react-router-dom";

const S3 = () => {
    return (
        <div className="paper-details">
            <h1>S3 Math exercise Free PDF download</h1>
            <h1>中三數學練習免費PDF下載</h1>
            <div className="paper-list">
                <h2>Ch 1: Factorization 因式分解</h2>
                <a href="https://drive.google.com/file/d/124dweN2jbNrNUFf8Fto5m3NN1snSWlSf/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1aHVgWLCzxAqUnDk_Rsh-aSXIZAsjwWJZ/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 2: Laws of Indices 指數定律</h2>
                <a href="https://drive.google.com/file/d/1Wjgy2YjUGrzEaSsDW68LRAFF78gV4ja8/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1sZdqIJFNaSABa8GKa0w0c9KDoDkIDwD3/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
             <div className="paper-list">
                <h2>Ch 3: Percentages 百分數</h2>
                <a href="https://drive.google.com/file/d/16JLl-Rnc5rhaoG2-G84wkKYKS1kd_ySL/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1OwqtRtqpuPzQIyFKsok4F5yLlfcDXTYz/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
             <div className="paper-list">
                <h2>Ch 4: Linear Inequalities in One Unknown 一元一次不等式</h2>
                <a href="https://drive.google.com/file/d/1jLOz_iuBD5RW3LqYsK3T2Rbdvbbd2vIB/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1d45owsNn4AuOUYrBwg7A_cQyz8h27TLG/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
             <div className="paper-list">
                <h2>Ch 5: Measures of Central Tendency 集中趨勢的量度</h2>
                <a href="https://drive.google.com/file/d/19adx0TgWxdc8igNLjuCS_ofOwNLe4gjk/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1RclMvQvoTaMdufaScDkz1Zr8BSphttBq/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 6: Deductive Geometry 演繹幾何</h2>
                <a href="https://drive.google.com/file/d/1OXTpksY_4Scy_2XwtY4dohZy3hR9lPmk/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1fhIg1LYvLB9kwUL5C-jJ21OiB7dZxnZh/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
             <div className="paper-list">
                <h2>Ch 7: Quadrilaterals 四邊形</h2>
                <a href="https://drive.google.com/file/d/1hSPTxaTKIU6HmlurlP_c4n291awOP0aD/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1jk7BdOVHOReS5aLFC4dQ8CJejpnU1x4G/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 8: 3D Figures 立體圖形</h2>
                <a href="https://drive.google.com/file/d/18dA1Xbs3l5fDYVNjhORNbjT2GrIhbOEs/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1Q7Boe2XZxJizReydOt4Y4MxCFEsqv_cT/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 9: Mensuration, Area and Volume 求積法，面積和體積</h2>
                <a href="https://drive.google.com/file/d/1_zcG2wgLX7bCXfoWR1NYiHUrXehNQ_KY/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1--Z87f4r28RQRjRb2Xvw6jorZ3AUJEFg/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 10: Applications of Trigonometry 三角學的應用</h2>
                <a href="https://drive.google.com/file/d/1X0n3lBUFVimMrIaN2iOIFDnOJF3XfS9o/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/19XvrmrItPYfbdTpPVyvUZRmsTDWJ8VXI/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
             <div className="paper-list">
                <h2>Ch 11: Coordinate Geometry of Straight Line 直線的坐標幾何</h2>
                <a href="https://drive.google.com/file/d/1YYwGxvboZ_-Ft0rAqp3J2HWQN3nXM5BQ/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1yX4Y7jIsb6qkiu8fm8qwQwTp4qkS--iP/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 12: Probability 概率</h2>
                <a href="https://drive.google.com/file/d/1-iyEb-BedFQgIxP12ivgxaEciOGlBtS8/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1KwzlXPvrrRbtZX7pRLrkwgN1GlA9R9OJ/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
        </div>
    );
}

export default S3;
