// open web: npm run start
// open json server: npx json-server --watch data/db.json --port 8000
// download react js bundle : npm install react-router-dom@5

import Navbar from './Navbar';
import Home from './Home';
import Create from './Create';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PaperDetails from './PaperDetails';
import NotFound from './NotFound';
import S4 from './S4';
import S3 from './S3';
import S5 from './S5';
import Footer from './Footer';

function App() {

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/S4">
              <S4 />
            </Route>
            <Route path="/S3">
              <S3 />
            </Route>
            <Route path="/S5">
              <S5 />
            </Route>
            {/* <Route path="/papers/:id">
              <PaperDetails />
            </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </Router>

  );
}

export default App;
