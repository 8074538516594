import { Link } from "react-router-dom";

const Home = () => {

    return (  
        <div className="home">
            <div className="home-page-message-seo">
                <h1>Free math PDF exercise download</h1>
                <h1>免費數學PDF練習下載</h1>
            </div>
            
            <div className="home-page-direct-button">
                <Link to="/s3"style={{textDecoration:"none", color:"white"}}>中三數學練習</Link>
            </div>

            <div className="home-page-direct-button">
                <Link to="/s4"style={{textDecoration:"none", color:"white"}}>中四數學練習</Link>
            </div>

            <div className="home-page-direct-button">
                <Link to="/s5"style={{textDecoration:"none", color:"white"}}>中五數學練習</Link>
            </div>

            <div className="home-page-message">
                <h4>Other topics coming soon ~~</h4>
                <h4>其他題目將不定時推出，敬請期待~~</h4>
                <p>Contact us: mathexe0@gmail.com</p>
            </div>
        </div>
    );
}

export default Home;
