const Footer = () => {
    return (
        
        <div className="footer">
            <hr />
            <p>&copy;{new Date().getFullYear()} Math-EXE | All right reserved</p>
        </div>
    );
}

export default Footer;