// import { Link } from "react-router-dom";

const S4 = () => {
    return (
        <div className="paper-details">
            <h1>S4 Math exercise Free PDF download</h1>
            <h1>中四數學練習免費PDF下載</h1>
            <div className="paper-list">
                <h2>Ch 1: Complex Number 複數</h2>
                <a href="https://drive.google.com/file/d/1H7IMDKpaEEQTuzj4RaU-jFGlsbx9IPvT/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1R4Upy8M2g8CWRk8C45Pp7P3SROaK6zKJ/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 2: Equation of Straight Line 直線方程</h2>
                <a href="https://drive.google.com/file/d/1d5IFJ-CyxLs5x7ZxJfiMt00V6kD4hFpj/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1NId3YlHPjylWoEfVcMPzz2O3q3LGPru0/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 3: Quadratic Equations 一元二次方程</h2>
                <a href="https://drive.google.com/file/d/1Aw-SOWe2KJySMSS3rNhenDKWdc-IZlfS/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1vazg8waUZgbfUkntdTli5yU3uPslIWK1/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 4: Function 函數</h2>
                <a href="https://drive.google.com/file/d/1gv8k8WZvcpmjsFiK_Yk2NAalKaKpHV8z/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1B2RaQd9MAyy4cjHsWT9dAh9VadFq1XY6/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 5: Quadratic Function 二次函數</h2>
                <a href="https://drive.google.com/file/d/1RFm57vUilm-y7YR6Wq6M5lzSi73ljhyg/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1vKDxXn4g7oWdRQnwq937CGBkEbqciFrA/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 6: More about Polynomials 續多項式</h2>
                <a href="https://drive.google.com/file/d/10Ds0Lwfa_Sc3qXfQo4JtENhLp9dL1WBa/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1xb-9kgC6aCA8n2N-oY-G22MUBvPtqnuc/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 7: Exponential Function 指數函數</h2>
                <a href="https://drive.google.com/file/d/1bz3lpJwbt7judPdevqXeAwzgUTELwaNj/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1PlcpwtuFyMYx_vTw7qinyHQb3mFH_1rU/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 8: Logarithm Function 對數函數</h2>
                <a href="https://drive.google.com/file/d/1zAQvfbIXZQSea4A-YB-9luQfsiLh-W-8/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1Xhvz734qluqWyTC7me5CBqcde0EWY2aY/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 9: Rational Function 有理函數</h2>
                <a href="https://drive.google.com/file/d/1uFxO5MTKWsbq8X3EL5_pnJUYYlTFcPHg/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1rPKnEz-y4b8o1eMB4A7qHO5oOZEZHxGn/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 10: Basic Properties of Circles 1 圓的基本性質1</h2>
                <a href="https://drive.google.com/file/d/1fJKeQ7ke18tnbJqFQJFeRfDcOaYRflZi/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/111IpRo9eU7nxR-6-saIyrEdUFu82hSDj/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 11: Basic Properties of Circles 2 圓的基本性質2</h2>
                <a href="https://drive.google.com/file/d/1pzVcCahBYpkSajE1OnKlwkFF8K_1Y93c/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1Je4s9b4nA1YZSWZqhYTxOhnl0s4pRfg-/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
            <div className="paper-list">
                <h2>Ch 12: Basic Trigonometry 基本三角學</h2>
                <a href="https://drive.google.com/file/d/1ry53BvBkiZZt48lk9vTzbShTQHg78aVO/view?usp=sharing" target="_blank">EX 1 (Eng)</a>
                <a href="https://drive.google.com/file/d/1h_VSjJENo2a3VF8-5tfsfMcHvzMocGAi/view?usp=sharing" target="_blank">EX 1 (中文版)</a>
            </div>
        </div>
    );
}

export default S4;
