import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="navbar">
            <Link to="/" style={{ fontSize: "25px", color: "#f1356d", fontWeight: "bold" }}>Math EXE</Link>
            <div className="links">
                <Link to="/">Home</Link>

                {/* question bank dropdown */}
                
                <Link to="/s3">S3</Link>
                <Link to="/s4">S4</Link>
                <Link to="/s5">S5</Link>
                {/* <Link to="/s1">Prog</Link> */}
            </div>
        </nav>
    );
}

export default Navbar;
